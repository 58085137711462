.App {
  text-align: center;
}

.App-logo {
  max-width: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.gumb{
  width: 100%;
  margin-bottom: 0.5rem;
}



.broj{
  text-align:right;
}

.sredina{
  text-align: center;
}

.mojnaslov
{
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.oglasnaslov
{
  text-align: center;
  padding-top: 40px;
  padding-bottom: 20px;
}


.mojznak
{
  font-size: 18px;
  width: 250px;
  margin-left: 20px;
  margin-bottom: 40px;
  text-align: center;
  align-items: center;
}

.mojakartica
{
  width: 250px;
  margin-left: 20px;
  margin-bottom: 40px;
  align-items: center;
}

.karticaoglas
{
  width: 400px;
  margin-left: 20px;
}

.fotografijaoglas
{
  width: 400px;
  margin-left: 20px;
}

.karticaporuka
{
  width: 600px;

}
.mojlink
{
font-weight: bold;
text-decoration: none;
}

.mojredak
{
  margin-top: 40px;
  margin-bottom: 40px;
}

.redakoglas
{
  margin-bottom: 40px;
}

.podnaslov
{
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 0px;
}

.fotkaoglas
{
max-width: 150px;
}

.mojdiv
{
  background-color: white;
  display: flex;
  justify-content:center;
  margin:0 auto;
  max-width: 960px;
}

.obrazacdiv
{
  padding-left: 20px;
  padding-right: 20px;
}

.obrazaclogin
{
  padding: 20px;
}


.naslovna
{
  padding-left: 35px;
  padding-right: 35px;
  margin-bottom: 20px;
}


.mojheader
{
  padding-left: 0;
  padding-right: 0;
}

.poznat
{
padding-left: 20px;
margin-bottom: 20px;
}

.form-check-input {
  width: 2em;
  height: 2em;
  
} 

.form-check-reverse {
  text-align: center;
  margin-right: 9em;
}

